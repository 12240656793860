import React, { Component } from "react"
import FormatCard from "./FormatCard"

class FormatArea extends Component {
  render() {
    return (
      <div id="formatSelectorArea" className="module">
        {this.props.formatList.map((element) => {
          return (
            <FormatCard
              format={element}
              key={element.en}
              onFormatChange={this.props.onFormatChange}
              selectedFormat={this.props.selectedFormat}
              selectedLanguage={this.props.selectedLanguage}
            />
          )
        })}
      </div>
    )
  }
}

export default FormatArea
