import React, { Component } from "react"

class ConformityCheckerArea extends Component {
  render() {
    var topLabel

    if (typeof this.props.selectedSpecName === "undefined") {
      topLabel = this.props.labels.pleaseSelect[this.props.selectedLanguage]
    } else if (this.props.selectedSpecName === "No match") {
      topLabel = this.props.labels.noMatch[this.props.selectedLanguage]
    } else {
      topLabel = this.props.selectedSpecName[this.props.selectedLanguage]
    }

    return (
      <div id="conformityCheckerArea">
        <div className="card">
          <div className="conformityCheckerTable">
            <div className="specNameFirstRow">
              <div>{topLabel}</div>
            </div>
            <div className="tableFirstRow">
              <div> </div>
            </div>
            <div>
              <div> {this.props.labels.size[this.props.selectedLanguage]} </div>
            </div>
            <div>
              <div>{this.props.labels.weight[this.props.selectedLanguage]}</div>
            </div>
            <div>
              <div>
                {this.props.labels.extension[this.props.selectedLanguage]}
              </div>
            </div>
            <div className="tableFirstRow">
              <div>
                {this.props.labels.uploadedFile[this.props.selectedLanguage]}
              </div>
            </div>
            <div>
              <div> {this.props.uploadSize} </div>
            </div>
            <div>
              <div>
                {this.props.uploadWeight > 0
                  ? Math.floor(this.props.uploadWeight / 100) / 10 + "ko"
                  : ""}
              </div>
            </div>
            <div>
              <div> {this.props.uploadExtension} </div>
            </div>
            <div className="tableFirstRow">
              <div>
                {this.props.labels.selectedSpec[this.props.selectedLanguage]}
              </div>
            </div>
            <div>
              <div> {this.props.specSize} </div>
            </div>
            <div>
              <div>
                {this.props.specWeight > 0
                  ? Math.floor(this.props.specWeight / 100) / 10 + "ko"
                  : ""}
              </div>
            </div>
            <div>
              <div> {this.props.specExtensions.join(", ")} </div>
            </div>
            <div className="tableFirstRow">
              <div>{this.props.labels.status[this.props.selectedLanguage]}</div>
            </div>
            <div>
              <div>
                {this.props.specSize === this.props.uploadSize &&
                this.props.specSize !== ""
                  ? "✅"
                  : "❌"}
              </div>
            </div>
            <div>
              <div>
                {this.props.uploadWeight > 0 &&
                this.props.uploadWeight < this.props.specWeight
                  ? "✅"
                  : "❌"}
              </div>
            </div>
            <div>
              <div>
                {this.props.specExtensions.includes(this.props.uploadExtension)
                  ? "✅"
                  : "❌"}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConformityCheckerArea
