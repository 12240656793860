import React, { Component } from "react"

class DeviceCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onDeviceChange(this.props.device)
  }

  render() {
    var className =
      this.props.selectedDevice === this.props.device ? "selected card" : "card"
    return (
      <div className={className} onClick={(e) => this.handleClick(e)}>
        {" "}
        {this.props.device}{" "}
      </div>
    )
  }
}

export default DeviceCard
