import React, { Component } from "react"
import WebsiteCard from "./WebsiteCard"

class WebsiteArea extends Component {
  render() {
    return (
      <div id="websiteSelectorArea" className="module">
        {this.props.websiteList.map((element) => {
          return (
            <WebsiteCard
              website={element}
              key={element}
              onWebsiteChange={this.props.onWebsiteChange}
              selectedWebsite={this.props.selectedWebsite}
            />
          )
        })}
      </div>
    )
  }
}

export default WebsiteArea
