import React, { Component } from "react"
import DeviceCard from "./DeviceCard"

class DeviceArea extends Component {
  render() {
    return (
      <div id="deviceSelectorArea" className="module">
        {this.props.deviceList.map((element) => {
          return (
            <DeviceCard
              device={element}
              key={element}
              onDeviceChange={this.props.onDeviceChange}
              selectedDevice={this.props.selectedDevice}
            />
          )
        })}
      </div>
    )
  }
}

export default DeviceArea
