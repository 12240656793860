import React, { Component } from "react"

class Language extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.onLanguageChange()
  }
  render() {
    return (
      <div
        id="languageSelector"
        className="language card"
        onClick={this.handleClick}
      >
        <div id="alternativeLanguage">
          {this.props.language === "en" ? "FRANÇAIS" : "ENGLISH"}
        </div>
      </div>
    )
  }
}

export default Language
