import React, { Component } from "react"

class Image extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      imageLoaded: false,
      imageWidth: "100%",
    }
  }

  handleChange(e) {
    this.props.onImageChange(e.target.width + "x" + e.target.height)
    this.setState({
      imageLoaded: true,
      imageWidth: e.target.width,
    })
  }

  render() {
    var visibility
    if (typeof this.state.imageLoaded !== "undefined") {
      this.state.imageLoaded
        ? (visibility = "visible")
        : (visibility = "hidden")
    }

    var uploadedImageStyle,
      headerZoneStyle,
      borderZoneStyle,
      safeZoneStyle,
      safeZoneHeightStyle,
      contentZoneStyle,
      imageZoneStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      galleryStyle = {
        visibility: visibility,
      },
      transformScale = 1

    if (this.state.imageWidth > window.screen.width) {
      transformScale = 0.95 * (window.screen.width / this.state.imageWidth)
    }

    if (typeof this.props.specs[this.props.selectedSpec] !== "undefined") {
      if (
        Object.keys(this.props.specs[this.props.selectedSpec].skinGuidelines)
          .length > 0
      ) {
        galleryStyle = {
          gridTemplateColumns:
            "1fr " +
            this.props.specs[this.props.selectedSpec].skinGuidelines
              .skinContentWidth +
            "px 1fr",
          gridTemplateRows:
            this.props.specs[this.props.selectedSpec].skinGuidelines
              .skinTopBorderHeight +
            "px " +
            this.props.specs[this.props.selectedSpec].skinGuidelines
              .skinHeaderHeight +
            "px 1fr",
          visibility: visibility,
          display: "grid",
          justifyItems: "center",
          width: this.state.imageWidth,
          transformOrigin: "top center",
          transform: "scale(" + transformScale + ")",
        }

        uploadedImageStyle = {
          gridColumnStart: 1,
          gridColumnEnd: -1,
          gridRowStart: 1,
          gridRowEnd: -1,
        }

        console.log("---------");
        console.log(this.props.specs[this.props.selectedSpec].headerWebsite);
        console.log("---------");

        borderZoneStyle = {
          gridColumnStart: 1,
          gridColumnEnd: -1,
          gridRowStart: 1,
          gridRowEnd: 2,
          borderBottom: "red 3px dotted",
          backgroundColor: "rgba(255,0,0,0.5)",
          width: "100%",
        }

        safeZoneStyle = {
          gridColumnStart: 1,
          gridColumnEnd: 4,
          gridRowStart: 1,
          gridRowEnd: 4,
          borderTop: "green 3px dotted",
          borderRight: "green 3px dotted",
          borderLeft: "green 3px dotted",
          backgroundColor: "rgba(124,255,0,0.5)",
          opacity: 1,
          visibility: "visible",
          width: "" +
              this.props.specs[this.props.selectedSpec].skinGuidelines
                  .skinSafeZoneWidth + "px",
        }

        var sizeHeight          = this.props.specs[this.props.selectedSpec].size;
        var headerWebsiteHeight = this.props.specs[this.props.selectedSpec].skinGuidelines.headerWebsite;

        var sizeHeightInt       = parseInt(sizeHeight.slice(sizeHeight.indexOf('x') + 1));
        var sizeHeaderHeightInt = parseInt(headerWebsiteHeight);

        var foldLineZone = sizeHeightInt - sizeHeaderHeightInt;

        safeZoneHeightStyle = {
          position: 'absolute',
          top: foldLineZone + "px",
          left: 0,
          right: 0,
          borderTop: "blue 3px dotted",
          borderRight: "blue 3px dotted",
          borderLeft: "blue 3px dotted",
          backgroundColor: "rgba(0,184,255)",
          opacity: 0.8,
          visibility: "visible",
          zIndex: 1,
        }

        contentZoneStyle = {
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 4,
          borderTop: "red 3px dotted",
          borderRight: "red 3px dotted",
          borderLeft: "red 3px dotted",
          backgroundColor: "rgba(255,0,0,0.5)",
          opacity: 1,
          visibility: "visible",
          width: "100%",
        }
      } else {
        galleryStyle = {
          width: this.state.imageWidth,
          transformOrigin: "top center",
          transform: "scale(" + transformScale + ")",
        }
      }
    } else {
      galleryStyle = {
        width: this.state.imageWidth,
        transformOrigin: "top center",
        transform: "scale(" + transformScale + ")",
      }
    }

    return (
      <div className="imageZone" style={imageZoneStyle}>
        <div> </div>
        <div className="gallery" style={galleryStyle}>
          <img
            alt=""
            className="uploadedImage"
            style={uploadedImageStyle}
            src={this.props.src}
            onLoad={this.handleChange}
          />
          <div className="borderZone" style={borderZoneStyle}></div>
          <div className="headerZone" style={headerZoneStyle}></div>
          <div className="safeZone" style={safeZoneStyle}></div>
          <div className="safeZoneHeightStyle" style={safeZoneHeightStyle}></div>
          <div className="contentZone" style={contentZoneStyle}></div>
        </div>
      </div>
    )
  }
}

export default Image
