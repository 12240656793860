import React, { Component } from "react"

class FormatCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onFormatChange(this.props.format)
  }

  render() {
    var className =
      this.props.selectedFormat === this.props.format ? "selected card" : "card"
    return (
      <div className={className} onClick={(e) => this.handleClick(e)}>
        {this.props.format[this.props.selectedLanguage]}
      </div>
    )
  }
}

export default FormatCard
