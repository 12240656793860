import React, { Component } from "react"
import "./App.scss"
import FileUpdloaderArea from "./components/FileUpdloaderArea"
import ConformityCheckerArea from "./components/ConformityCheckerArea"
import Image from "./components/Image"
import WebsiteArea from "./components/WebsiteArea"
import DeviceArea from "./components/DeviceArea"
import FormatArea from "./components/FormatArea"
import Language from "./components/Language"

class App extends Component {
  constructor(props) {
    super(props)
    this.handleSpecNameChange = this.handleSpecNameChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleImgSrcChange = this.handleImgSrcChange.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleWebsiteChange = this.handleWebsiteChange.bind(this)
    this.handleFormatChange = this.handleFormatChange.bind(this)
    this.handleDeviceChange = this.handleDeviceChange.bind(this)
    this.hangleLanguageChange = this.hangleLanguageChange.bind(this)
    this.findEligibleSpec = this.findEligibleSpec.bind(this)
    this.onlyUnique = this.onlyUnique.bind(this)
    this.dynamicSort = this.dynamicSort.bind(this)
    this.handleData = this.handleData.bind(this)
    this.state = {
      specs: {},
      selectedLanguage: "en",
      selectedWebsite: "",
      selectedFormat: {},
      selectedDevice: "",
      selectedSpec: {},
      specFound: false,
      websiteList: [],
      deviceList: [],
      formatList: [],
      file: {},
      imgSrc: "",
      uploadSize: "",
      uploadWeight: "",
      uploadExtension: "",
      specExtensions: [],
      specSize: "",
      specWeight: "",
      loaded: false,
      loadingStatus: "LOADING...",
      labels: {
        loading: {
          en: "LOADING",
          fr: "CHARGEMENT",
        },
        title: {
          en: "MEDIA SPECIFICATIONS CONFORMITY CHECKER",
          fr: "VÉRIFICATEUR DE CONFORMITÉ AUX SPÉCIFICATIONS MÉDIA",
        },
        websiteSelect: {
          en: "SELECT A WEBSITE",
          fr: "SÉLECTIONNEZ UN SITE",
        },
        formatSelect: {
          en: "SELECT A FORMAT",
          fr: "SÉLECTIONNEZ UN FORMAT",
        },
        deviceSelect: {
          en: "SELECT A DEVICE",
          fr: "SÉLECTIONNEZ UN TYPE D'APPAREIL",
        },
        uploadFile: {
          en: "UPLOAD A FILE",
          fr: "TÉLÉCHARGEZ UN FICHIER",
        },
        checkConformity: {
          en: "CHECK CONFORMITY",
          fr: "VÉRIFIEZ LA CONFORMITÉ",
        },
        dragAndDrop: {
          en: "Click to chose a file or drag and drop it here",
          fr: "Cliquez pour choisir un fichier ou glissez et déposez-le dans cette zone",
        },
        fileUploaded: {
          en: "File successfuly uploaded",
          fr: "Fichier téléchargé avec succès",
        },
        uploadedFile: {
          en: "uploaded file",
          fr: "fichier téléchargé",
        },
        selectedSpec: {
          en: "selected spec",
          fr: "spec sélectionnée",
        },
        status: {
          en: "status",
          fr: "statut",
        },
        size: {
          en: "size",
          fr: "dimension",
        },
        weight: {
          en: "weight",
          fr: "poids",
        },
        extension: {
          en: "extension",
          fr: "type",
        },
        pleaseSelect: {
          en: "Please select website, format and device",
          fr: "Veuillez sélectionner un site, un format et un type d'appareil",
        },
        noMatch: {
          en: "No match",
          fr: "Aucune correspondance",
        },
      },
    }
  }

  selectedSpec = ""

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === "-") {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  handleData() {
    fetch(`https://${process.env.REACT_APP_ASSETS_DOMAIN}/data`)
      .then((res) => res.json())
      .then((result) => {
        console.log("got data", result)
        this.setState({ specs: result })

        var websiteList = []
        Object.entries(result).forEach((spec) => {
          spec[1].websites.forEach((website) => {
            websiteList.push(website)
          })
        })
        websiteList = websiteList.filter(this.onlyUnique)
        websiteList = websiteList.sort()

        this.setState({
          websiteList: websiteList,
        })

        var deviceList = []
        Object.entries(result).forEach((spec) => {
          spec[1].devices.forEach((device) => {
            deviceList.push(device)
          })
        })
        deviceList = deviceList.filter(this.onlyUnique)
        deviceList = deviceList.sort()

        this.setState({
          deviceList: deviceList,
        })

        var formatList = []
        Object.entries(result).forEach(([key, value]) => {
          formatList.push(value.format)
        })
        formatList = Array.from(new Set(formatList.map(JSON.stringify))).map(
          JSON.parse,
        )
        formatList.sort(this.dynamicSort("en"))
        this.setState({
          formatList: formatList,
          loaded: true,
          loadingStatus: "loaded",
        })
      })
      .catch((error) => {
        this.setState({
          loadingStatus: error,
        })
      })
  }

  hangleLanguageChange() {
    if (this.state.selectedLanguage === "en") {
      this.setState({ selectedLanguage: "fr" })
      document.title = "WEBEDIA - " + this.state.labels.title.fr
    } else {
      this.setState({ selectedLanguage: "en" })
      document.title = "WEBEDIA - " + this.state.labels.title.en
    }
  }

  componentDidMount() {
    this.handleData()
  }

  handleSpecNameChange(selectedSpecName) {
    this.setState({
      selectedSpecName: selectedSpecName,
      selectedSpec: this.state.specs[selectedSpecName],
    })
  }

  handleImageChange(size) {
    this.setState({
      uploadSize: size,
    })
  }

  handleWebsiteChange(website) {
    this.setState(
      {
        specFound: false,
        selectedWebsite: website,
        selectedSpec: this.selectedSpec,
      },
      function () {
        this.findEligibleSpec()
      },
    )
  }

  handleFormatChange(format) {
    this.setState(
      {
        specFound: false,
        selectedFormat: format,
        selectedSpec: this.selectedSpec,
      },
      function () {
        this.findEligibleSpec()
      },
    )
  }

  handleDeviceChange(device) {
    this.setState(
      {
        specFound: false,
        selectedDevice: device,
        selectedSpec: this.selectedSpec,
      },
      function () {
        this.findEligibleSpec()
      },
    )
  }

  handleImgSrcChange(imgSrc) {
    this.setState({
      imgSrc: imgSrc,
    })
  }

  handleFileChange(file) {
    this.setState({
      file: file,
      uploadExtension: file.type.split("/")[1],
      uploadWeight: file.size,
    })
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      this.handleImgSrcChange(reader.result)
    }
  }

  findEligibleSpec() {
    if (
      this.state.selectedWebsite !== "" &&
      this.state.selectedFormat !== {} &&
      this.state.selectedDevice !== ""
    ) {
      var specFound = false
      Object.entries(this.state.specs).forEach((spec) => {
        if (!specFound) {
          if (
            spec[1].websites.includes(this.state.selectedWebsite) &&
            spec[1].format.en === this.state.selectedFormat.en &&
            spec[1].format.fr === this.state.selectedFormat.fr &&
            spec[1].devices.includes(this.state.selectedDevice)
          ) {
            this.setState({
              selectedSpec: spec[0],
              specSize: this.state.specs[spec[0]].size,
              specExtensions: this.state.specs[spec[0]].extensions,
              specWeight: this.state.specs[spec[0]].maxWeight,
              selectedSpecName: this.state.specs[spec[0]].name,
            })
            specFound = true
            return
          } else {
            specFound = false
            this.setState({
              selectedSpec: undefined,
              specSize: undefined,
              specExtensions: [],
              specWeight: undefined,
              selectedSpecName: "No match",
            })
          }
        }
      })
    }
  }

  render() {
    var loaded
    this.state.loaded ? (loaded = "loaded") : (loaded = "")
    return (
      <div id="App">
        <div id="loader" data-class="loader" className={loaded}>
          <div className="loaderCircle">
            <svg
              width="150"
              viewBox="0 0 640 263"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M0 0L79.07 255.47H158.15L79.07 0H0Z" fill="#001478" />
                <path
                  d="M158.15 0L237.22 255.47H316.29L237.22 0H158.15Z"
                  fill="#001478"
                />
                <path
                  d="M237.22 0L158.15 255.47H79.07L158.15 0H237.22Z"
                  fill="#0028BE"
                />
                <path
                  d="M395.37 0L316.29 255.47H237.22L316.29 0H395.37Z"
                  fill="#0028BE"
                />
                <path
                  d="M553.51 0L474.44 255.47H395.37L474.44 0H553.51Z"
                  fill="#0028BE"
                />
                <path
                  d="M597.51 262.34C585.17 262.34 574.753 258.063 566.26 249.51C557.767 240.957 553.517 230.483 553.51 218.09C553.51 206.69 557.76 196.8 566.26 188.42C574.76 180.04 585.177 175.85 597.51 175.85C603.105 175.775 608.656 176.852 613.818 179.014C618.979 181.177 623.64 184.379 627.51 188.42C635.837 196.807 640 206.697 640 218.09C640 230.49 635.833 240.963 627.5 249.51C619.167 258.057 609.17 262.333 597.51 262.34Z"
                  fill="#0028BE"
                />
              </g>
            </svg>
          </div>
          <div className="loaderText">
            {this.state.labels.loading[this.state.selectedLanguage]}
          </div>
        </div>
        <main>
          <nav>
            <div className="logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                viewBox="0 0 640 400.89"
              >
                <g id="Calque_2" data-name="Calque 2">
                  <g id="Calque_1-2" data-name="Calque 1">
                    <polygon
                      className="cls-1"
                      fill="#b0c0ea"
                      points="0 0 79.07 255.47 158.15 255.47 79.07 0 0 0"
                    />
                    <polygon
                      className="cls-1"
                      fill="#b0c0ea"
                      points="158.15 0 237.22 255.47 316.29 255.47 237.22 0 158.15 0"
                    />
                    <polygon
                      className="cls-1"
                      fill="#fff"
                      points="237.22 0 158.15 255.47 79.07 255.47 158.15 0 237.22 0"
                    />
                    <polygon
                      className="cls-1"
                      fill="#fff"
                      points="395.37 0 316.29 255.47 237.22 255.47 316.29 0 395.37 0"
                    />
                    <polygon
                      className="cls-1"
                      fill="#fff"
                      points="553.51 0 474.44 255.47 395.37 255.47 474.44 0 553.51 0"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M597.51,262.34q-18.51,0-31.25-12.83t-12.75-31.42q0-17.1,12.75-29.67t31.25-12.57a40.78,40.78,0,0,1,30,12.57Q640,201,640,218.09q0,18.6-12.5,31.42T597.51,262.34Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M192.29,379.23c6.63,0,12.29-2.59,15.52-6.14l19.4,11.15c-7.92,11-19.88,16.65-35.24,16.65-27.65,0-44.78-18.59-44.78-42.67s17.46-42.68,43-42.68c23.6,0,41.06,18.27,41.06,42.68a46.69,46.69,0,0,1-1,9.69H172.89C176,376.32,183.4,379.23,192.29,379.23Zm14.87-29.42C204.58,340.43,197.3,337,190,337c-9.22,0-15.36,4.52-17.62,12.77Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M323.8,358.22c0,24.08-17.46,42.67-38.63,42.67-10.84,0-18.76-3.71-24.09-9.86v7.6H236.83V285.47h24.25V325.4c5.33-6.14,13.25-9.86,24.09-9.86C306.34,315.54,323.8,334.13,323.8,358.22Zm-24.25,0c0-12.13-8.08-19.73-19.23-19.73s-19.24,7.6-19.24,19.73,8.08,19.72,19.24,19.72S299.55,370.34,299.55,358.22Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M371.69,379.23c6.63,0,12.29-2.59,15.52-6.14l19.4,11.15c-7.92,11-19.88,16.65-35.24,16.65-27.64,0-44.78-18.59-44.78-42.67s17.46-42.68,43-42.68c23.6,0,41.06,18.27,41.06,42.68a46.69,46.69,0,0,1-1,9.69H352.3C355.37,376.32,362.8,379.23,371.69,379.23Zm14.88-29.42C384,340.43,376.71,337,369.43,337c-9.21,0-15.36,4.52-17.62,12.77Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M500.42,285.47V398.63H476.17V391c-5.34,6.15-13.26,9.86-24.09,9.86-21.18,0-38.64-18.59-38.64-42.67s17.46-42.68,38.64-42.68c10.83,0,18.75,3.72,24.09,9.86V285.47Zm-24.25,72.75c0-12.13-8.09-19.73-19.24-19.73s-19.24,7.6-19.24,19.73,8.09,19.72,19.24,19.72S476.17,370.34,476.17,358.22Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M508.81,317.8h24.24v80.83H508.81Z"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M625.62,317.8v80.83H601.37V391c-5.33,6.15-13.25,9.86-24.09,9.86-21.17,0-38.63-18.59-38.63-42.67s17.46-42.68,38.63-42.68c10.84,0,18.76,3.72,24.09,9.86v-7.6Zm-24.25,40.42c0-12.13-8.08-19.73-19.24-19.73s-19.23,7.6-19.23,19.73,8.08,19.72,19.23,19.72S601.37,370.34,601.37,358.22Z"
                    />
                    <polygon
                      className="cls-1"
                      fill="#fff"
                      points="126.32 317.8 113.81 358.21 101.3 317.8 76.28 317.8 63.77 358.21 51.26 317.8 26.25 317.8 51.26 398.63 76.28 398.63 88.79 358.21 101.3 398.63 126.32 398.63 151.34 317.8 126.32 317.8"
                    />
                    <path
                      className="cls-1"
                      fill="#fff"
                      d="M521.15,310.69A12.36,12.36,0,0,1,512,307a12.5,12.5,0,0,1-3.72-9.17,11.76,11.76,0,0,1,3.72-8.65,12.48,12.48,0,0,1,9.11-3.66,12.48,12.48,0,0,1,12.39,12.31,12.64,12.64,0,0,1-3.65,9.17A11.78,11.78,0,0,1,521.15,310.69Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="h1">
              <h1> {this.state.labels.title[this.state.selectedLanguage]} </h1>
            </div>
            <Language
              onLanguageChange={this.hangleLanguageChange}
              language={this.state.selectedLanguage}
            />
          </nav>
          <section>
            <div id="websiteSelector">
              <div id="websiteSelectorTitle" className="title">
                {this.state.labels.websiteSelect[this.state.selectedLanguage]}
              </div>
              <WebsiteArea
                websiteList={this.state.websiteList}
                onWebsiteChange={this.handleWebsiteChange}
                selectedWebsite={this.state.selectedWebsite}
              />
            </div>
            <div id="formatSelector">
              <div id="formatSelectorTitle" className="title">
                {this.state.labels.formatSelect[this.state.selectedLanguage]}
              </div>
              <FormatArea
                formatList={this.state.formatList}
                onFormatChange={this.handleFormatChange}
                selectedFormat={this.state.selectedFormat}
                selectedLanguage={this.state.selectedLanguage}
              />
            </div>
            <div id="deviceSelector">
              <div id="deviceSelectorTitle" className="title">
                {this.state.labels.deviceSelect[this.state.selectedLanguage]}
              </div>
              <DeviceArea
                deviceList={this.state.deviceList}
                onDeviceChange={this.handleDeviceChange}
                selectedDevice={this.state.selectedDevice}
              />
            </div>
            <div className="columns">
              <div id="fileUploader">
                <div id="fileUpdloaderTitle" className="title">
                  {this.state.labels.uploadFile[this.state.selectedLanguage]}
                </div>
                <FileUpdloaderArea
                  onFileChange={this.handleFileChange}
                  labels={this.state.labels}
                  selectedLanguage={this.state.selectedLanguage}
                />
              </div>
              <div id="conformityChecker">
                <div id="conformityCheckerTitle" className="title">
                  {
                    this.state.labels.checkConformity[
                    this.state.selectedLanguage
                    ]
                  }
                </div>
                <ConformityCheckerArea
                  uploadSize={this.state.uploadSize}
                  uploadWeight={this.state.uploadWeight}
                  uploadExtension={this.state.uploadExtension}
                  specSize={this.state.specSize}
                  specWeight={this.state.specWeight}
                  specExtensions={this.state.specExtensions}
                  selectedSpecName={this.state.selectedSpecName}
                  selectedSpec={this.state.selectedSpec}
                  labels={this.state.labels}
                  selectedLanguage={this.state.selectedLanguage}
                />
              </div>
            </div>
          </section>
        </main>
        <Image
          src={this.state.imgSrc}
          onImageChange={this.handleImageChange}
          specs={this.state.specs}
          selectedSpec={this.state.selectedSpec}
        />
      </div>
    )
  }
}

export default App
